import axiosInstance from "../AxiosInstance";
import {
  APPROVE_USER_DETAILS,
  BLOCK_USERS,
  DELETE_USERS,
  GET_USERS,
  POST_USER_DETAILS,
} from "./UserApiEndPoints";
export function getAllUsers(currentPage, limit, search, filterType) {
  return axiosInstance.get(
    GET_USERS +
      `?page=${currentPage}&limit=${limit}&search=${search}&filter=${filterType}`
  );
}

export function getReferFriendConditionApi() {
  return axiosInstance.get("admin/referAmount");
}

export function getReferenceUserApi(userId, currentPage, limit) {
  return axiosInstance.get(
    "admin/referUserList" + `?page=${currentPage}&limit=${limit}&id=${userId}`
  );
}
export function getCouponPurchasedApi(currentPage, limit, userId) {
  return axiosInstance.get(
    "admin/purchaseCouponList" +
      `?page=${currentPage}&limit=${limit}&id=${userId}`
  );
}

export function getPointHistoryApi(currentPage, limit, id) {
  return axiosInstance.get(
    "admin/pointHistory" + `?page=${currentPage}&limit=${limit}&userId=${id}`
  );
}

export function getTierApi() {
  return axiosInstance.get("admin/getTiers");
}

export function deleteUser(id) {
  return axiosInstance.delete(DELETE_USERS + `?userId=${id}`);
}
export function pointStatusApi(id) {
  const postData = { pointId: id };
  return axiosInstance.put("admin/confirmPoint", postData);
}
export function deductUserPointApi(postData) {
  return axiosInstance.put("admin/deductPoint", postData);
}

export function blockUser(id) {
  const postData = { userId: id };
  return axiosInstance.put(BLOCK_USERS, postData);
}
export function setTierApi(postData) {
  return axiosInstance.put("admin/setTiers", postData);
}
export function postNotificationApi(postData) {
  return axiosInstance.post("admin/sendNotification", postData);
}

export function addUserPointsApi(id, point, description, status, isAffiliate) {
  const postData = { userId: id, point, description, status, isAffiliate };
  return axiosInstance.put("admin/addPoint", postData);
}
export function getUserPurchasedOffersApi(
  userId,
  programId,
  page,
  limit,
  type
) {
  return axiosInstance.get(
    `admin/usersPurchaseOffer?page=${page}&limit=${limit}&userId=${userId}&programId=${programId}&type=${type}`
  );
}

export function approveUser(userId) {
  const postData = { userId };
  return axiosInstance.post(APPROVE_USER_DETAILS, postData);
}
export function postUser(
  responseImage,
  fname,
  lname,
  email,
  dob,
  phNumber,
  typeOfTrainerSelected,
  locationSelected,
  servicesSelected,
  years,
  clients,
  noOfCertificate,
  fitnessCertificate,
  password,
  countryCode
) {
  const postData = {
    profile: responseImage,
    fName: fname,
    lName: lname,
    email,
    dob,
    phone: phNumber,
    typeOfTrainer: typeOfTrainerSelected,
    trainningLocation: locationSelected,
    services: servicesSelected,
    years,
    clients,
    noOfCertificate: noOfCertificate,
    fitnessCertificate: fitnessCertificate,
    password,
    countryCode,
  };

  return axiosInstance.post(POST_USER_DETAILS, postData);
}
export async function policiesServicesApi(data) {
  const response = await axiosInstance.post("admin/createPrivacyPolicy", data);
  return response;
}
export async function getTermsServicesApi(data) {
  const response = await axiosInstance.post(
    "admin/createTermsAndConditions",
    data
  );
  return response;
}
export async function setPointsLimitApi(data) {
  const response = await axiosInstance.put("admin/setPointLimit", data);
  return response;
}

export async function gerPoliciesServicesApi(type, language) {
  const response = await axiosInstance.get(
    `admin/privacyPolicy?type=${type}&language=${language}`
  );
  return response;
}
export async function gerTermsServicesApi(type, language) {
  const response = await axiosInstance.get(
    `admin/termAndConditions?type=${type}&language=${language}`
  );
  return response;
}
export async function gerExportFileApi(type) {
  const response = await axiosInstance.get(`admin/exportData?type=${type}`);
  return response;
}
