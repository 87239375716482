import React, { useState, useEffect } from "react";
import { Button, Table, Card, Col, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import AddEditIndustry from "../modal/AddEditIndustry";
import {
  deleteIndustryApi,
  getIndustryApi,
} from "../../services/Vendor Services/VendorService";
import moment from "moment";
import Pagination from "../common/Pagination";

const IndustryManagement = () => {
  const [industries, setIndustries] = useState([]);
  const [loader, setLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [isLoader1, setIsLoader1] = useState(false);
  const [isLoader2, setIsLoader2] = useState(false);

  const limit = 10;
  function ScrollToTop() {
    window.scrollTo(0, 0);
  }
  useEffect(() => {
    fetchIndustries();
    ScrollToTop();
  }, [currentPage]);

  const fetchIndustries = () => {
    setLoader(true);
    getIndustryApi(currentPage, limit)
      .then((response) => {
        setIndustries(response.data.data);
        setPageCount(Math.ceil(response.data.data.total / limit));
        setLoader(false);
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.data || error?.response?.data?.message
        );
        setLoader(false);
      });
  };

  const handleDelete = async (id) => {
    setIsLoader1(true);
    try {
      const res = await deleteIndustryApi(id);
      toast.success("Industry deleted successfully");
      fetchIndustries();
    } catch (error) {
      toast.error(
        error?.response?.data?.data || error?.response?.data?.message
      );
    } finally {
      setIsLoader1(false);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-baseline pb-3">
        <h3 className="">Industry Management</h3>
        <Button
          className="btn btn-primary btn-sm"
          onClick={() => setShowModal(true)}
        >
          Add Industry
        </Button>
      </div>
      {/* <Col> */}
      {loader ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" />
        </div>
      ) : (
        <Card>
          <Card.Body>
            <Table response>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Created at</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {industries?.industries?.map((industry) => (
                  <tr key={industry.id}>
                    <td>{industry?.title}</td>
                    <td>{moment(industry?.createdAt).format("ll")}</td>
                    <td>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => handleDelete(industry?.id)}
                        disabled={isLoader1}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {industries?.total === 0 && !loader && (
              <div className="justify-content-center d-flex my-5 ">
                Sorry, Data Not Found!
              </div>
            )}
            <div className="d-flex justify-content-between align-items-center">
              <div className="dataTables_info">
                Total Industry
                <span
                  style={{
                    color: "#042e77",
                    border: "1px solid #042e77",
                    padding: "5px 15px",
                    borderRadius: "5px",
                    marginLeft: "5px",
                  }}
                >
                  {industries?.total}
                </span>
              </div>
              <Pagination
                pageCount={pageCount}
                pageValue={currentPage}
                setPage={setCurrentPage}
              />
            </div>
          </Card.Body>
        </Card>
      )}
      {/* </Col> */}

      <AddEditIndustry
        show={showModal}
        onHide={() => setShowModal(false)}
        fetchIndustries={fetchIndustries}
      />
    </>
  );
};

export default IndustryManagement;
