import React, { useState } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { addIndustryApi } from "../../services/Vendor Services/VendorService";

const AddEditIndustry = ({ show, onHide, fetchIndustries }) => {
  const [formValues, setFormValues] = useState([{ name: "", error: "" }]);
  const [loader, setLoader] = useState(false);

  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const updatedFormValues = [...formValues];
    updatedFormValues[index][name] = value;
    updatedFormValues[index].error = "";
    setFormValues(updatedFormValues);
  };

  const handleAddInput = () => {
    setFormValues((prev) => [...prev, { name: "", error: "" }]);
  };

  const handleRemoveInput = (index) => {
    setFormValues((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSubmit = async () => {
    let hasError = false;
    const updatedFormValues = formValues.map(field => {
      if (!field.name.trim()) {
        hasError = true;
        return { ...field, error: "Industry name is required" };
      }
      return field;
    });

    if (hasError) {
      setFormValues(updatedFormValues);
      return;
    }

    setLoader(true);
    const industries = formValues.map((item) => item.name);
    try {
      await addIndustryApi({ industries });
      toast.success("Industries added successfully!");
      fetchIndustries();
      onHide();
    } catch (error) {
      toast.error(
        error?.response?.data?.data || error?.response?.data?.message
      );
    } finally {
      setLoader(false);
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Add New Industry</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div>
            <Form.Label>Industry Name</Form.Label>
            {formValues.map((field, index) => (
              <div
                key={index}
                className="d-flex flex-column"
                style={{ gap: "5px", marginBottom: "10px" }}
              >
                <div className="d-flex align-items-baseline" style={{ gap: "5px" }}>
                  <Form.Group className="flex-grow-1">
                    <Form.Control
                      type="text"
                      name="name"
                      value={field.name}
                      onChange={(e) => handleChange(index, e)}
                      placeholder="Enter industry name"
                      isInvalid={!!field.error}
                    />
                    {field.error && (
                      <Form.Control.Feedback type="invalid">
                        {field.error}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <Button
                    variant="danger"
                    onClick={() => handleRemoveInput(index)}
                    disabled={formValues.length === 1}
                  >
                    X
                  </Button>
                </div>
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-end">
            <Button variant="secondary" size="sm" onClick={handleAddInput}>
              Add Another
            </Button>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" size="sm" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="primary" size="sm" onClick={handleSubmit}>
          {loader ? <Spinner size="sm" animation="border" /> : "Add Industry"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddEditIndustry;
