import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { getReferFriendConditionApi } from "../../services/User/UserService";

export default function ReferFriendCondition({ show, onHide }) {
  const [referFriendCondition, setReferFriendCondition] = useState([]);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    setLoader(true);
    getReferFriendConditionApi()
      .then((response) => {
        setReferFriendCondition(response.data.data);
      })
      .catch((error) => {
        setLoader(false);
      })
      .finally(() => {
        setLoader(false);
      });
  }, []);

  console.log(referFriendCondition, "referFriendCondition");

  const formatPoints = (value) => {
    return Number(value).toFixed(2).replace(/\.00$/, '');
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <h4 className="m-0">Refer Friend Conditions</h4>
      </Modal.Header>
      <div className="pt-2 pl-4 pr-4 pb-4">
        <p>
          In this referral program, when an existing user (Alice) refers a
          friend (Bob) to join the platform, Alice will only earn {formatPoints(referFriendCondition.referrerPoints)} points if
          she already has at least {formatPoints(referFriendCondition.requiredReferPoints)} points herself. If Alice meets this
          requirement, she receives {formatPoints(referFriendCondition.referrerPoints)} points immediately after Bob successfully
          signs up using her referral link. Bob, the new user, can also earn a
          <span className=""> {formatPoints(referFriendCondition.refereePoints)}-point welcome bonus</span>, but only after he accumulates {formatPoints(referFriendCondition.requiredReferPoints)} points through
          regular platform activities like making purchases or engaging with
          content. Once Bob reaches this threshold, he receives his {formatPoints(referFriendCondition.refereePoints)}-point
          bonus, completing the referral cycle and rewarding both users for
          their participation. All points are fictional and should be editable
          from the admin panel.
        </p>
      </div>
    </Modal>
  );
}
