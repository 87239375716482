import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Spinner, Row, Col } from "react-bootstrap";
import toast from "react-hot-toast";
import { setReferConditionApi } from "../../services/Vendor Services/VendorService";
import { getReferFriendConditionApi } from "../../services/User/UserService";
const ReferFriendConditionModal = ({ show, onHide, onSubmit }) => {
  const [formValues, setFormValues] = useState({
    refereePoints: "",
    referrerPoints: "",
    requiredReferPoints: "",
  });

  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState({});

  const fetchReferFriendCondition = async () => {
    setLoader(true);
    getReferFriendConditionApi()
      .then((response) => {
        const data = response.data.data;
        setFormValues({
          refereePoints: data.refereePoints || "",
          referrerPoints: data.referrerPoints || "",
          requiredReferPoints: data.requiredReferPoints || "",
        });
      })
      .catch((error) => {
        toast.error("Failed to load current conditions");
      })
      .finally(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    fetchReferFriendCondition();
  }, []);

  const formatPoints = (value) => {
    return Number(value).toFixed(2).replace(/\.00$/, '');
  };

  const validate = () => {
    const errors = {};
    if (
      !formValues.refereePoints ||
      isNaN(formValues.refereePoints) ||
      formValues.refereePoints <= 0
    ) {
      errors.refereePoints = "Please enter a valid value greater than 0.";
    }
    if (
      !formValues.referrerPoints ||
      isNaN(formValues.referrerPoints) ||
      formValues.referrerPoints <= 0
    ) {
      errors.referrerPoints = "Please enter a valid value greater than 0.";
    }
    if (
      !formValues.requiredReferPoints ||
      isNaN(formValues.requiredReferPoints) ||
      formValues.requiredReferPoints <= 0
    ) {
      errors.requiredReferPoints = "Please enter a valid value greater than 0.";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" })); // Clear error when retyping
  };

  const handleSubmit = async () => {
    if (!validate()) return;

    setLoader(true);
    try {
      const res = await setReferConditionApi(formValues);
      toast.success("Conditions set successfully!");
      onHide();
    } catch (error) {
      toast.error(error?.response?.data?.data);
    } finally {
      fetchReferFriendCondition();
      setLoader(false);
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered dialogClassName="modal-80w">
      <Modal.Header closeButton>
        <Modal.Title>Set Refer a Friend Conditions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-4">
          <p>
            In this referral program, when an existing user (Alice) refers a
            friend (Bob) to join the platform, Alice will only earn {formatPoints(formValues.referrerPoints)} points if
            she already has at least {formatPoints(formValues.requiredReferPoints)} points herself. If Alice meets this
            requirement, she receives {formatPoints(formValues.referrerPoints)} points immediately after Bob successfully
            signs up using her referral link. Bob, the new user, can also earn a
            <span className=""> {formatPoints(formValues.refereePoints)}-point welcome bonus</span>, but only after he accumulates {formatPoints(formValues.requiredReferPoints)} points through
            regular platform activities like making purchases or engaging with
            content. Once Bob reaches this threshold, he receives his {formatPoints(formValues.refereePoints)}-point
            bonus, completing the referral cycle and rewarding both users for
            their participation. All points are fictional and should be editable
            from the admin panel.
          </p>
        </div>

        <Form>
          <Row className="g-3">
            <Col md={4}>
              <Form.Group>
                <Form.Label>Points earned by referee</Form.Label>
                <Form.Control
                  type="number"
                  name="refereePoints"
                  value={formValues.refereePoints}
                  onChange={handleChange}
                  placeholder="Enter points"
                  isInvalid={!!errors.refereePoints}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.refereePoints}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>Points earned by referrer</Form.Label>
                <Form.Control
                  type="number"
                  name="referrerPoints"
                  value={formValues.referrerPoints}
                  onChange={handleChange}
                  placeholder="Enter points"
                  isInvalid={!!errors.referrerPoints}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.referrerPoints}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>Points required by referee</Form.Label>
                <Form.Control
                  type="number"
                  name="requiredReferPoints"
                  value={formValues.requiredReferPoints}
                  onChange={handleChange}
                  placeholder="Enter points"
                  isInvalid={!!errors.requiredReferPoints}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.requiredReferPoints}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" size="sm" onClick={onHide}>
          Cancel
        </Button>
        <Button
          variant="primary"
          size="sm"
          onClick={handleSubmit}
          disabled={loader}
        >
          {loader ? <Spinner size="sm" animation="border" /> : "Submit"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReferFriendConditionModal;
