import React, { useEffect, useState } from "react";
import PageTitle from "../layouts/PageTitle";
import AddSpinWheelOffers from "../modal/AddSpinWheelOffers";
import {
  getWheelOfferListApi,
  deleteWheelApi,
  addSpinWheelApi,
  editWheelOfferApi,
} from "../../services/Vendor Services/VendorService";
import { Badge, Card, Col, Dropdown, Table } from "react-bootstrap";
import moment from "moment";
import UserTableSkeleton from "../components/UserTableSkeleton";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import toast from "react-hot-toast";
import ConfirmationModal from "../modal/ConfirmationModal";
import Pagination from "../common/Pagination";

export default function SpinWheel(props) {
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  const [loader, setLoader] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const limit = 10;

  function getTableData() {
    setLoader(true);
    getWheelOfferListApi(currentPage, limit)
      .then((response) => {
        setData(response.data.data);
        const total = response.data.data.total;
        setPageCount(Math.ceil(total / limit));
        setLoader(false);
      })
      .catch((error) => {
        console.log(error, "error");
        setLoader(false);
      });
  }

  async function deleteWheelHistory(item) {
    setDeleteModal(true);
    setSelectedItem(item);
  }
  function onSendVenderItem(item) {
    props.history.push({
      pathname: "./spin-wheel-details",
      state: item,
    });
  }

  useEffect(() => {
    getTableData();
  }, []);

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center pl-3 pr-3 pb-3">
        <h3 className="m-0">Wheel Contest List ghjkl</h3>
        <div className="">
          <button
            className="btn btn-sm btn-primary"
            onClick={() =>
              props.history.push({
                pathname: "/add-contest-form",
                state: {
                  title: "Add Wheel Contest",
                  successMessage: "Added Wheel contest successfully",
                },
              })
            }
          >
            Add Contest
          </button>
        </div>
      </div>
      <Col>
        <Card>
          <Card.Body>
            <Table responsive>
              <thead style={{ color: "black" }}>
                <tr>
                  <th>
                    <strong>name</strong>
                  </th>
                  <th className="text-center">
                    <strong>Points Required</strong>
                  </th>
                  <th>
                    <strong>Start Date</strong>
                  </th>
                  <th>
                    <strong>End Date</strong>
                  </th>
                  <th>
                    <strong>created At</strong>
                  </th>
                  <th className="text-center">
                    <strong>status</strong>
                  </th>
                  <th>
                    <strong>ACTION</strong>
                  </th>
                </tr>
              </thead>
              {loader ? (
                <UserTableSkeleton count={8} />
              ) : (
                <tbody>
                  {data?.contests?.map((item, i) => (
                    <tr key={i}>
                      <td onClick={() => onSendVenderItem(item)}>
                        {item.name}
                      </td>
                      <td
                        className="text-center"
                        onClick={() => onSendVenderItem(item)}
                      >
                        {item.pointsRequired}
                      </td>
                      <td onClick={() => onSendVenderItem(item)}>
                        {moment(item.startTime).format("ll")}{" "}
                      </td>
                      <td onClick={() => onSendVenderItem(item)}>
                        {moment(item.endTime).format("ll")}{" "}
                      </td>
                      <td onClick={() => onSendVenderItem(item)}>
                        {moment(item.createdAt).format("ll")}
                      </td>
                      <td className="text-center">
                        {item.isExpire ? (
                          <Badge variant="danger light">Expired</Badge>
                        ) : (
                          <Badge variant="success light">Active</Badge>
                        )}
                      </td>

                      <td>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="info light"
                            className="light sharp btn btn-info i-false"
                          >
                            {svg1}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {!item?.isExpire && (
                              <Dropdown.Item
                                onClick={() =>
                                  props.history.push({
                                    pathname: "/add-contest-form",
                                    state: {
                                      selectedItem: item,
                                      successMessage:
                                        "Edit Wheel contest successfully",
                                      title: "Edit Wheel Contest",
                                    },
                                  })
                                }
                              >
                                Edit
                              </Dropdown.Item>
                            )}
                            <Dropdown.Item
                              onClick={() => deleteWheelHistory(item)}
                            >
                              Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </Table>
            {data?.total === 0 && !loader ? (
              <div className="justify-content-center d-flex my-5 ">
                Sorry, Data Not Found!
              </div>
            ) : (
              ""
            )}
            <div className="d-flex justify-content-between align-items-center pt-3">
              <div className="dataTables_info">
                Total Wheel Contest
                <span
                  style={{
                    color: "#042e77",
                    border: "1px solid #042e77",
                    padding: "5px 15px",
                    borderRadius: "5px",
                    marginLeft: "5px",
                  }}
                >
                  {data?.total}
                </span>
              </div>
              <Pagination
                pageCount={pageCount}
                pageValue={currentPage}
                setPage={setCurrentPage}
              />
            </div>
          </Card.Body>
        </Card>
      </Col>

      {deleteModal && (
        <ConfirmationModal
          show={deleteModal}
          onHide={() => setDeleteModal(false)}
          successMessage={"Wheel Contest Deleted successfully"}
          api={deleteWheelApi}
          refresh={getTableData}
          content={"Are you sure you want to Delete this Contest ?"}
          data={selectedItem}
        />
      )}
    </div>
  );
}
