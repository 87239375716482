import React, { useCallback, useEffect, useState } from "react";
import {
  Dropdown,
  Card,
  Table,
  Badge,
  Col,
  Button,
  Spinner,
} from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import Pagination from "../common/Pagination";
import toast from "react-hot-toast";
import {
  approveUser,
  blockUser,
  deleteUser,
  gerExportFileApi,
  getAllUsers,
} from "../../services/User/UserService";
import UserTableSkeleton from "../components/UserTableSkeleton";
import defaultImage from "../../images/emptyImg.jpg";
import SetLimitAndUserPoints from "../modal/SetLimitAndUserPoints";
import AddUserPoints from "../modal/AddUserPoints";
import SetTierModal from "../modal/SetTierModal";
import ConfirmationModal from "../modal/ConfirmationModal";
import Filters from "../components/Filters";
import DeductUserPointsModal from "../modal/DeductUserPointsModal";
import ReferFriendCondition from "../modal/ReferFriendCondition";
import SetReferFriendCondition from "../modal/SetReferFriendCondition";

export default function UserManagement(props) {
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [tierModalShow, setTierModalShow] = useState(false);
  const [search, setSearch] = useState("");
  const [addPointsModal, setAddPointsModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [fileUrl, setFileUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showLimitPointModal, setShowLimitPointModal] = useState(false);
  const [filterType, setFilterType] = useState("");
  const [isDeductModal, setIsDeductModal] = useState(false);
  const [isRefererModal, setIsRefererModal] = useState(false);
  const [isRefererCondition, setIsRefererCondition] = useState(false);

  const limit = 10;
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  const FilterOptions = [
    { label: "All", value: "" },
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inActive" },
    { label: "Deleted", value: "deleted" },
  ];
  function handleError(e) {
    e.target.src = defaultImage;
  }
  function onSendVenderItem(item) {
    props.history.push({
      pathname: "./user-details",
      state: item,
    });
  }

  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const handleSearch = useCallback(
    debounce((value) => {
      setSearch(value);
      setCurrentPage(0);
    }, 500),
    []
  );

  function getTableData() {
    setLoader(true);
    getAllUsers(currentPage, limit, search, filterType)
      .then((response) => {
        setUsers(response.data.data);
        setLoader(false);
        const total = response.data.data.total;
        setPageCount(Math.ceil(total / limit));
      })
      .catch((error) => {
        console.log(error, "error");
        setLoader(false);
      });
  }

  async function getExportFileUrl() {
    setIsLoading(true);
    try {
      const response = await gerExportFileApi("User");
      setFileUrl(response?.data?.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error, "error");
      setIsLoading(false);
    }
  }
  const downloadFile = () => {
    if (fileUrl) {
      const link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute("download", "report.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  function onDelete(item) {
    setDeleteModal(true);
    setSelectedUser(item);
  }
  function onAction(id) {
    setLoader(true);
    blockUser(id)
      .then((response) => {
        if (response.data.data) {
          toast.success("User Deactivate successfully");
        } else {
          toast.success("User Active successfully");
        }
        getTableData();
        setLoader(false);

        console.log(response);
      })
      .catch((error) => {
        setLoader(false);
        toast.error(error.response.data.data);
      });
  }
  useEffect(() => {
    getTableData();
  }, [currentPage, search, filterType]);

  return (
    <div>
      <div className="d-flex align-items-baseline justify-content-between p-3">
        <h3 className="">Users Management</h3>
        <div className="d-flex">
          {fileUrl ? (
            <button className="btn btn-primary btn-sm" onClick={downloadFile}>
              {isLoading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Download file"
              )}
            </button>
          ) : (
            <button
              className="btn btn-primary btn-sm mr-2"
              onClick={getExportFileUrl}
            >
              {isLoading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Export file"
              )}
            </button>
          )}
          <button
            className="btn btn-sm btn-primary mr-2"
            onClick={() => setIsRefererCondition(true)}
          >
            Set Refer Condition
          </button>
        </div>
      </div>

      <Col>
        <div className="d-flex justify-content-between align-items-center">
          <div className="form-group" style={{ width: "35%" }}>
            <input
              type="text"
              name="table_search"
              className="form-control"
              placeholder="Search"
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>
          <div className="d-flex">
            <div className="mr-2">
              <Filters
                type={"filter"}
                setFilterType={setFilterType}
                FilterOption={FilterOptions}
                filterType={filterType}
              />
            </div>
            {/* <button
              className="btn btn-sm btn-primary mr-2"
              onClick={() => setIsRefererModal(true)}
            >
              Refer conditions
            </button> */}

            <button
              className="btn btn-sm btn-primary mr-2"
              onClick={() => {
                setShowLimitPointModal(true);
                setSelectedUser(users);
              }}
            >
              Set Point limit
            </button>
            <button
              className="btn btn-sm btn-primary mr-2"
              onClick={() => setTierModalShow(true)}
            >
              Set Tier
            </button>
          </div>
        </div>
        <Card>
          <Card.Body>
            <Table responsive>
              <thead style={{ color: "black" }}>
                <tr>
                  <th>
                    <strong>IMAGE</strong>
                  </th>
                  <th>
                    <strong>NAME</strong>
                  </th>
                  <th>
                    <strong>EMAIL</strong>
                  </th>
                  <th>
                    <strong>PHONE</strong>
                  </th>

                  <th className="text-center">
                    <strong>Points</strong>
                  </th>
                  <th className="text-center">
                    <strong>Refer Users</strong>
                  </th>
                  <th>
                    <strong>Status</strong>
                  </th>
                  <th>
                    <strong>ACTION</strong>
                  </th>
                </tr>
              </thead>
              {loader ? (
                <UserTableSkeleton count={8} />
              ) : (
                <tbody>
                  {users?.users?.map((item, i) => (
                    <tr
                      key={i}
                      className={item.isDeleted ? "disabled-row" : ""}
                    >
                      <td onClick={() => onSendVenderItem(item)}>
                        <img
                          src={`https://api.paybagapp.com/${item?.image}`}
                          width={50}
                          height={50}
                          style={{ borderRadius: "25px" }}
                          onError={handleError}
                        />
                      </td>
                      <td onClick={() => onSendVenderItem(item)}>
                        {item?.name?.length > 20
                          ? item?.name?.slice(0, 20) + "..."
                          : item?.name}
                      </td>
                      <td onClick={() => onSendVenderItem(item)}>
                        {item?.email?.length > 25
                          ? item?.email?.slice(0, 25) + "..."
                          : item?.email}
                      </td>
                      <td onClick={() => onSendVenderItem(item)}>
                        +{item?.countryCode} {item.phoneNumber}
                      </td>
                      <td className="text-center">{item.generalPoint}</td>
                      <td
                        className="text-center table-column"
                        onClick={() =>
                          props.history.push({
                            pathname: "/reference-management",
                            state: item?.id,
                          })
                        }
                      >
                        {item.referUserCount}
                      </td>
                      <td className="text-center">
                        {item?.isDeleted ? (
                          <Badge variant="danger light">Deleted</Badge>
                        ) : (
                          <>
                            {item.isBlocked ? (
                              <Badge variant="danger light">Deactivate</Badge>
                            ) : (
                              <Badge variant="success light">Active</Badge>
                            )}
                          </>
                        )}
                      </td>
                      <td className="text-center">
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="info light"
                            className="light sharp btn btn-info i-false"
                            disabled={item?.isDeleted ? true : false}
                          >
                            {svg1}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => {
                                setAddPointsModal(true);
                                setSelectedUser(item);
                              }}
                            >
                              Add Points
                            </Dropdown.Item>
                            {item?.generalPoint > "0.00" && (
                              <Dropdown.Item
                                onClick={() => {
                                  setIsDeductModal(true);
                                  setSelectedUser(item);
                                }}
                              >
                                Deduct Points
                              </Dropdown.Item>
                            )}
                            {item.isBlocked ? (
                              <Dropdown.Item onClick={() => onAction(item.id)}>
                                Enable
                              </Dropdown.Item>
                            ) : (
                              <Dropdown.Item onClick={() => onAction(item.id)}>
                                Disable
                              </Dropdown.Item>
                            )}

                            <Dropdown.Item onClick={() => onDelete(item)}>
                              Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </Table>
            {users?.total === 0 && !loader ? (
              <div className="justify-content-center d-flex my-5 ">
                Sorry, Data Not Found!
              </div>
            ) : (
              ""
            )}
            <div className="d-flex justify-content-between align-items-center">
              <div className="dataTables_info">
                Total User
                <span
                  style={{
                    color: "#042e77",
                    border: "1px solid #042e77",
                    padding: "5px 15px",
                    borderRadius: "5px",
                    marginLeft: "5px",
                  }}
                >
                  {users?.total}
                </span>
              </div>
              <Pagination
                pageCount={pageCount}
                pageValue={currentPage}
                setPage={setCurrentPage}
              />
            </div>
          </Card.Body>
        </Card>
      </Col>
      <AddUserPoints
        show={addPointsModal}
        onHide={() => setAddPointsModal(false)}
        userData={selectedUser}
        refresh={getTableData}
      />
      {tierModalShow && (
        <SetTierModal
          show={tierModalShow}
          handleClose={() => setTierModalShow(false)}
          title={"Set Tiers"}
        />
      )}
      {deleteModal && (
        <ConfirmationModal
          show={deleteModal}
          onHide={() => setDeleteModal(false)}
          successMessage={"Deleted User successfully"}
          api={deleteUser}
          refresh={getTableData}
          content={"Are you sure you want to Delete this User ?"}
          data={selectedUser}
        />
      )}
      {showLimitPointModal && (
        <SetLimitAndUserPoints
          show={showLimitPointModal}
          onHide={() => setShowLimitPointModal(false)}
          refresh={getTableData}
          data={selectedUser}
        />
      )}
      {isDeductModal && (
        <DeductUserPointsModal
          show={isDeductModal}
          onHide={() => setIsDeductModal(false)}
          userData={selectedUser}
          refresh={getTableData}
        />
      )}
      {isRefererModal && (
        <ReferFriendCondition
          show={isRefererModal}
          onHide={() => setIsRefererModal(false)}
        />
      )}
      {isRefererCondition && (
        <SetReferFriendCondition
          show={isRefererCondition}
          onHide={() => setIsRefererCondition(false)}
        />
      )}
    </div>
  );
}
